<template>
  <svg
    class="spinner"
    data-testid="spinner"
    :viewBox="viewbox"
    :style="svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <rect
      :style="rect.style"
      :clip-path="rect.clipPath"
      x="0"
      y="0"
      :width="width"
      :height="height"
    />

    <defs>
      <clipPath :id="clipPathId">
        <slot>
          <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
          <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
          <rect x="0" y="80" rx="3" ry="3" width="350" height="10" />
          <rect x="0" y="100" rx="3" ry="3" width="400" height="10" />
          <rect x="0" y="120" rx="3" ry="3" width="360" height="10" />
        </slot>
      </clipPath>

      <linearGradient :id="gradientId">
        <stop offset="0%" :stop-color="primary">
          <animate
            attributeName="offset"
            values="-2; 1"
            :dur="formatedSpeed"
            repeatCount="indefinite"
          />
        </stop>

        <stop offset="50%" :stop-color="secondary">
          <animate
            attributeName="offset"
            values="-1.5; 1.5"
            :dur="formatedSpeed"
            repeatCount="indefinite"
          />
        </stop>

        <stop offset="100%" :stop-color="primary">
          <animate
            attributeName="offset"
            values="-1; 2"
            :dur="formatedSpeed"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from 'vue';
import { FULLY_COMPATIBLE } from '../../utils/compat';

const validateColor = (color: string) => /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/.test(color);

// Source: https://github.com/LucasLeandro1204/vue-content-loading/blob/master/src/components/VueContentLoading.vue
export default defineComponent({
  name: 'BaseContentSkeleton',
  compatConfig: FULLY_COMPATIBLE,
  props: {
    /**
     * Controls if the skeleton is left-aligned or right-aligned.  (The implementation of
     * this prop effectively just mirrors the skeleton over the Y-axis.)
     */
    rtl: {
      default: false,
      type: Boolean,
    },
    /**
     * Controls the speed of the animation in the skeleton.
     */
    speed: {
      default: 2,
      type: Number,
    },
    /**
     * Controls the width (in svg units) of the svg's viewbox. The default template for this
     * skeleton does not vary or resize based on the viewbox, so all this will do is add right padding.
     */
    width: {
      default: 400,
      type: [Number, String],
    },
    /**
     * Controls the height (in svg units) of the svg's viewbox. The default template for this
     * skeleton does not vary or resize based on the viewbox, so all this will do is add bottom padding.
     */
    height: {
      default: 130,
      type: [Number, String],
    },
    /**
     * The color of the rectangles in the skeleton.
     */
    primary: {
      type: String,
      default: '#f0f0f0',
      validator: validateColor,
    },
    /**
     * The color of the animated portion of the skeleton.
     */
    secondary: {
      type: String,
      default: '#e0e0e0',
      validator: validateColor,
    },
  },
  setup() {
    const uid = getCurrentInstance()?.uid;

    return {
      uid,
    };
  },
  computed: {
    viewbox(): string {
      return `0 0 ${this.width} ${this.height}`;
    },
    formatedSpeed(): string {
      return `${this.speed}s`;
    },
    gradientId(): string {
      return `gradient-${this.uid}`;
    },
    clipPathId(): string {
      return `clipPath-${this.uid}`;
    },
    svg() {
      if (this.rtl) {
        return {
          transform: 'rotateY(180deg)',
        };
      }
      return {};
    },
    rect() {
      return {
        style: {
          fill: 'url(#' + this.gradientId + ')',
        },
        clipPath: 'url(#' + this.clipPathId + ')',
      };
    },
  },
});
</script>
